import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import UserprofilePage, { UserProfile } from './pages/user/UserProfile'
import { NotificationList, NotificationDetail } from './pages/notification'
import { PortList, PortDetail } from './pages/port'
import * as path from '../app/constants/path'
import { UserList } from './pages/user'
import { TransactionList, TransactionDetail } from './pages/transaction'

import { KycDetail, KycList } from './pages/kyc'
import { BankAccountList, BankAccountDetail } from './pages/bankaccount'
import { ArticleList, ArticleDetail } from './pages/article'
import { appConfig } from './constants/app-config'
import { Setting } from './pages/setting'
import { RewardList } from './pages/reward'
import RewardDetail from './pages/reward/RewardDetail'
import { Authorize } from './components/Authorize'
import { ROLE } from './services/user/user-typed'

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /users */
          <Redirect exact from="/" to={path.users()} />
        }

        <ContentRoute path="/users/:userId" component={UserProfile} />
        <ContentRoute path={path.users()} component={UserList} />
        <ContentRoute path="/usersnew" component={UserprofilePage} />
        <ContentRoute path={path.notificationCreate()} component={NotificationDetail} />
        <ContentRoute path={path.notificationDetail()} component={NotificationDetail} />
        <ContentRoute path={path.notification()} component={NotificationList} />

        <ContentRoute path={path.portDetail()} component={PortDetail} />
        <ContentRoute path={path.ports()} component={PortList} />
        <ContentRoute path={path.transactionDetail()} component={TransactionDetail} />
        <ContentRoute path={path.transactions()} component={TransactionList} />
        <ContentRoute path={path.kycDetail()} component={KycDetail} />
        <ContentRoute path={path.kycs()} component={KycList} />
        <ContentRoute path={path.bankAccountDetail()} component={BankAccountDetail} />
        <ContentRoute path={path.bankAccounts()} component={BankAccountList} />
        <ContentRoute path={path.setting()} component={Setting} />
        <ContentRoute path={path.rewardDetail()} component={RewardDetail} />
        <ContentRoute path={path.rewards()} component={RewardList} />

        <Redirect exact from="/dashboard" to={path.users()} />
        {appConfig.ENABLE_FEATURE_ARTICLE && appConfig.ENABLE_FEATURE_TRUE_MONEY && (
          <Authorize role={ROLE.ADMIN}>
            <Switch>
              <ContentRoute path={path.articleDetail()} component={ArticleDetail} />
              <ContentRoute path={path.createArticle()} component={ArticleDetail} />
              <ContentRoute path={path.article()} component={ArticleList} />
            </Switch>
          </Authorize>
        )}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}
