import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import humps from 'humps'
import { clearToken, getToken } from '../helper'
import { customRequestData, deepLoop } from './tools'
import { appConfig } from '../../app/constants/app-config'
import { compact, join, set } from 'lodash'

const createClient = (apiVersion?: string) => {
  const ax = axios.create({
    baseURL: join(compact([appConfig.API_HOST, apiVersion]), '/'),
  })

  ax.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = getToken()

    if (token) {
      set(request, 'headers.authorization', `Bearer ${getToken()}`)
    }
    if (process.env.REACT_APP_VERSION) {
      set(request, 'headers.x-app-version', process.env.REACT_APP_VERSION)
    }
    if (process.env.REACT_APP_BUILD_VERSION) {
      set(request, 'headers.x-app-build-version', process.env.REACT_APP_BUILD_VERSION)
    }

    if (request.params) {
      request.params = deepLoop(request.params, modifyRequestData)
    }
    if (request.data) {
      request.data = deepLoop(request.data, modifyRequestData)
      // request.data = humps.decamelizeKeys(request.data)
      customRequestData(request)
    }
    return request
  })
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      if (response.headers['content-type'].includes('application/json')) {
        response.data = humps.camelizeKeys(response.data)
        // deepLoop(response.data, data => {
        //   return data
        // })
      }
      // response.data = { data: response.data }
      return response
    },
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        clearToken()
        window.location.reload()
      }
      return Promise.reject(error)
    },
  )
  return ax
}

const modifyRequestData = (data: any) => {
  if (data) {
    if (dayjs.isDayjs(data)) {
      return data.format()
    }
  }
  return data
}

export const savvyGoldClient = createClient('v1')

export const savvyGoldApiWraper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method
    return Promise.resolve(res)
  } catch (e) {
    const { response, message } = e as any
    let { data } = response || {}
    if (data instanceof Blob) {
      const responseFromBlob = await data.text()
      data = JSON.parse(responseFromBlob || '')
    }
    const { message: message2, errorCode } = data || {}
    return Promise.reject(errorCode || message2 || message || e)
  }
}
