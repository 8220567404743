import React, { useMemo } from 'react'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import SubMenu from './SubMenu'
import * as paths from '../../../../../app/constants/path'
import { appConfig } from '../../../../../app/constants/app-config'
import { ROLE } from '../../../../../app/services/user/user-typed'
import { Authorize } from '../../../../../app/components/Authorize'
type OfficerMenuProp = {
  isActive: boolean
}

export function OfficerMenu({ isActive }: OfficerMenuProp) {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true),
    }
  }, [uiService])

  return (
    <div className={`tab-pane fade ${isActive && 'show active'}`}>
      <div className="aside-menu-wrapper flex-column-fluid px-10 py-5">
        {/* begin::Menu Container */}
        <div
          id="kt_aside_officer_menu"
          data-menu-vertical="1"
          className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}
        >
          {/* begin::Menu Nav */}
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <SubMenu title="Users" to="/users" />
            <SubMenu title="Notification" to="/notification" />
            <SubMenu title="Ports" to="/ports" />
            <SubMenu title="Kyc" to="/ekycs" />
            <SubMenu title="Transaction" to="/transactions" />
            <SubMenu title="BankAccount" to={paths.bankAccounts()} />
            {appConfig.ENABLE_FEATURE_ARTICLE && appConfig.ENABLE_FEATURE_TRUE_MONEY && (
              <SubMenu title="Article" to={paths.article()} />
            )}
            {appConfig.ENABLE_FEATURE_TRUE_MONEY && appConfig.ENABLE_FEATURE_GIVEAWAY && (
              <Authorize role={ROLE.SUPER_ADMIN}>
                <SubMenu title="Reward" to={paths.rewards()} />
              </Authorize>
            )}

            {/*end::1 Level*/}
          </ul>

          {/* end::Menu Nav */}
        </div>
        {/* end::Menu Container */}
      </div>
    </div>
  )
}
