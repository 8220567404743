import React, { useCallback, useMemo } from 'react'
import ListPage from '../../../components/templates/ListPage'
import Text from '../../../components/common/Text'
import {
  getTransactionTypeColor,
  replaceUnderScoreAndCapitalize,
  useRouter,
} from '../../../../utils/helper'
import * as paths from '../../../constants/path'
import { useGetAllTransactionsByPortId } from '../../../services/transaction/transaction-query'
import {
  EnumTransactionStatus,
  EnumTransactionType,
  EnumTransactionTypeCapitalize,
} from '../../../services/transaction/transaction-typed'
import numeral from 'numeral'
import dayjs from 'dayjs'
import { ColumnsProps } from '../../../components/common/Table'
import TransactionStatusTag from '../../transaction/TransactionList/TransactionStatusTag'
import { Typography } from '@material-ui/core'
import styled from '@emotion/styled'

const TextLib = styled(Typography)`
  line-height: 2 !important;
`

const TextColor = styled(TextLib)<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`

const column = () =>
  [
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: EnumTransactionTypeCapitalize) => {
        return (
          <Text bold style={{ color: getTransactionTypeColor(type) }}>
            {replaceUnderScoreAndCapitalize(type)}
          </Text>
        )
      },
    },
    {
      title: 'Gold Amount',
      dataIndex: 'goldAmount',
    },
    {
      title: 'Price (THB)',
      dataIndex: 'price',
      render: (price: string) => {
        return <Text>{numeral(price).format('0,0.00')} </Text>
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (createdAt: string) => {
        return <Text>{dayjs(createdAt).format('D MMMM  YYYY HH:mm ')}</Text>
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: EnumTransactionStatus) => {
        return <TransactionStatusTag status={status} />
      },
    },
  ] as ColumnsProps

type TransactionListProps = {
  portId: string
}

export const TransactionList = (props: TransactionListProps) => {
  const { portId } = props
  const { push, query } = useRouter()
  const { q, page } = query
  const { data: transactions, isLoading } = useGetAllTransactionsByPortId(portId, {
    q,
    page,
    type: EnumTransactionType.SELL,
    limit: 5,
    isManual: true,
  })
  const setQueryParam = useCallback(
    params => {
      push(paths.portDetail({ routeParam: { portId }, queryParam: { ...params } }))
    },
    [portId, push],
  )
  const onPageChange = useCallback(
    page => {
      setQueryParam({ page })
    },
    [setQueryParam],
  )

  const isHasTransaction = useMemo(() => {
    const total = transactions?.meta?.totalItems || 0
    return total > 0
  }, [transactions])

  return !isLoading && portId && isHasTransaction ? (
    <ListPage
      topbar={<TextColor variant="h5">Manual Cancel Saving Gold Transaction</TextColor>}
      tableProps={{
        columns: column(),
        data: transactions?.items,
        onPageChange,
        pagination: transactions?.meta,
      }}
    />
  ) : (
    <></>
  )
}

export default TransactionList
