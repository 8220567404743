import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography } from '@material-ui/core'
import styled from '@emotion/styled'
import { customAddressLabel, useRouter } from '../../../../utils/helper'
import { useGetPort, useSellClearPortByPortId } from '../../../services/port/port-query'
import DetailPage from '../../../components/templates/DetailPage'
import numeral from 'numeral'
import UserCard from '../../user/UserProfile/UserCard'
import { Address } from '../../../services/port/port-typed'
import { useGetImageUser } from '../../../services/user/user-query'
import ImageViewer from '../../../components/common/ImageViewer'
import TransactionCard from '../../transaction/TransactionDetail/TransactionCard'
import Text from '../../../components/common/Text'
import { TransactionList } from './TransactionList'
import Button from '../../../components/common/Button'
import { SellGoldModal } from './SellGoldModal'
import { Authorize } from '../../../components/Authorize'
import { ROLE } from '../../../services/user/user-typed'
import { appConfig } from '../../../constants/app-config'

const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
`

const TextLib = styled(Typography)`
  line-height: 2 !important;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 10px;
`

const Flex = styled(Grid)`
  display: flex;
  grid-gap: 10px;
  margin-top: 0px;
`
const TextColor = styled(TextLib)<{ color?: string }>`
  color: ${({ color }) => color};
  font-weight: bold !important;
`
const PortDetail = () => {
  const { portId } = useRouter().query
  const { data: port } = useGetPort(portId)
  const { mutate: sellClearPort } = useSellClearPortByPortId()

  const [isVisibleSellGoldModal, setIsVisibleSellGoldModal] = useState(false)

  const address = useMemo(() => {
    const obj: Partial<Record<Address['type'], Address>> = {}
    port?.address.forEach(data => {
      obj[data.type] = data
    })
    return obj
  }, [port])

  const { userId = '', sgGoldBalance = '', attachments = [], user, sgUserId = '' } = port || {}
  const { firstnameTh = '', lastnameTh = '' } = user || {}
  const fullname = `${firstnameTh} ${lastnameTh}`
  const { date = '' } = port?.dcaPlan || {}
  let b = Number(sgGoldBalance)
  const goldBalance = b / 15.244
  const balance = numeral(goldBalance).format('0,0.0000')
  const dcaDate = date ? `every ${date} of mount` : '-'
  const [fontCitizenId, setFontCitizenId] = useState('')
  const [backCitizenId, setBackCitizenId] = useState('')
  const [holdCitizenId, setHoldCitizenId] = useState('')

  useEffect(() => {
    attachments.forEach(({ type, id: imageId }) => {
      if (type === 'holdCitizen') {
        setHoldCitizenId(imageId)
      } else if (type === 'citizen') {
        setFontCitizenId(imageId)
      } else if (type === 'laserCode') {
        setBackCitizenId(imageId)
      }
    })
  }, [attachments])

  const { data: imageCitizen } = useGetImageUser(fontCitizenId, 'origin')
  const { image: imageCitizenPhoto } = imageCitizen! || '-'
  const { data: imageBackCitizen } = useGetImageUser(backCitizenId, 'origin')
  const { image: imageBackCitizenPhoto } = imageBackCitizen! || '-'
  const { data: imageHoldCitizen } = useGetImageUser(holdCitizenId, 'origin')
  const { image: imageHoldCitizenPhoto } = imageHoldCitizen! || '-'

  const showPhoto = useMemo(() => {
    if (!imageCitizenPhoto) {
      return 0
    } else if (!imageBackCitizenPhoto) {
      return 0
    } else if (!imageHoldCitizenPhoto) {
      return 0
    }
  }, [imageCitizenPhoto, imageHoldCitizenPhoto, imageBackCitizenPhoto])

  const portData = useMemo(
    () => [
      {
        label: 'Registered Address',
        value: customAddressLabel(address.citizen),
      },
      {
        label: 'Present Address',
        value: customAddressLabel(address.recent),
      },
      {
        label: 'Work Address ',
        value: customAddressLabel(address.work),
      },
      { label: 'DCA date', value: dcaDate },
      { label: 'Safe Gold User Id', value: sgUserId },
      {
        label: 'Attachments',
        value:
          showPhoto === 0 ? (
            <Text bold>-</Text>
          ) : (
            <ImageViewer
              images={[imageCitizenPhoto, imageBackCitizenPhoto, imageHoldCitizenPhoto]}
            />
          ),
      },
    ],
    [
      address.citizen,
      address.recent,
      address.work,
      dcaDate,
      imageCitizenPhoto,
      imageHoldCitizenPhoto,
      imageBackCitizenPhoto,
      sgUserId,
      showPhoto,
    ],
  )

  const onClickSellGold = useCallback(() => {
    setIsVisibleSellGoldModal(true)
  }, [])

  const onCloseSellGoldModal = useCallback(() => {
    setIsVisibleSellGoldModal(false)
  }, [])

  const onConfirmSellGold = useCallback(() => {
    sellClearPort(portId)
    setIsVisibleSellGoldModal(false)
  }, [portId, sellClearPort])

  return (
    <div>
      <DetailPage
        title={
          <Flex>
            <TextColor variant="h5">Balance</TextColor>
            <TextColor variant="h5">{balance ? `${balance} Baht Gold` : '-'}</TextColor>
            {goldBalance > 0 && (
              <Layout>
                {appConfig.ENABLE_FEATURE_SELL_MANUAL && appConfig.ENABLE_FEATURE_TRUE_MONEY && (
                  <Authorize role={ROLE.SUPER_ADMIN}>
                    <Button onClick={onClickSellGold} variant={'warning'}>
                      Cancel Saving Gold
                    </Button>
                  </Authorize>
                )}
              </Layout>
            )}
          </Flex>
        }
        contents={portData}
        gridItems={[
          <UserCard userId={userId} />,
          <TransactionCard portId={portId} fullname={fullname} />,
        ]}
      />
      <div
        style={{
          marginTop: 10,
        }}
      >
        <TransactionList portId={portId} />
      </div>
      {isVisibleSellGoldModal && (
        <SellGoldModal
          isOpen={isVisibleSellGoldModal}
          onConfirm={onConfirmSellGold}
          onClosed={onCloseSellGoldModal}
          balance={sgGoldBalance}
        />
      )}
    </div>
  )
}

export default PortDetail
