import { useMutation, useQueryClient } from 'react-query'

import { api } from '../../../utils/api'
import { clearToken, setToken } from '../../../utils/helper'
import { LoginFormValue } from '../../pages/auth/Login'
import { LoginType, VerifyEmail } from './auth-type'
import { USERS_URL, CURRENT_USER_URL } from '../user/user-query'
import { PasswordFormValue } from '../../pages/auth/shared/PasswordForm'

const AUTH_URL = 'internal/auth'
const LOGIN_URL = `${AUTH_URL}/email`

const VERIFY_EMAIL_URL = `${AUTH_URL}/verify-email`
// const REGISTER_URL = `${AUTH_URL}/register`
const UPDATE_PASSWORD_URL = `${AUTH_URL}/update-password`

export const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: LoginFormValue) => {
      const res = await api.savvyGold.post<LoginType>(LOGIN_URL, params)
      return res.data.data
    },
    {
      onSuccess: param => {
        setToken(param.accessToken)
        queryClient.setQueryData([USERS_URL, CURRENT_USER_URL], param.user)
      },
      onError: () => {},
    },
  )
}

export const useLogout = () => {
  const queryClient = useQueryClient()
  return useMutation(
    () => {
      return Promise.resolve('logout')
    },
    {
      onSuccess: () => {
        clearToken()
        queryClient.resetQueries()
      },
    },
  )
}

export const useVerifyEmail = () => {
  return useMutation<VerifyEmail, unknown, Pick<PasswordFormValue, 'email'>>(
    async params => {
      const response = await api.savvyGold.post<VerifyEmail>(VERIFY_EMAIL_URL, params)
      return response.data.data
    },
    {
      onError: () => {},
    },
  )
}

// export const useRegister = () => {
//   return useMutation<unknown, unknown, Omit<Omit<AccountFormValue, 'otp'>, 'confirmPassword'>>(
//     params => {
//       return api.savvyGold.post(REGISTER_URL, params)
//     },
//     {
//       onError: () => {},
//     },
//   )
// }

export const useUpdatePassword = () => {
  return useMutation<unknown, unknown, Omit<PasswordFormValue, 'confirmPassword'>>(
    params => {
      return api.savvyGold.patch(UPDATE_PASSWORD_URL, params)
    },
    {
      onError: () => {},
    },
  )
}
